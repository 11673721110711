import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { TopHeading } from '../components/heading';

class AboutmePage extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang="de"
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO title="Über mich" />
                <TopHeading>Über mich</TopHeading>
                <article>
                    <p>
                        Hi, ich bin Nicolas Hollmann, ein Student aus Hamburg.
                    </p>
                    <p>
                        Ich studiere Master Informatik an der{' '}
                        <a
                            href="https://www.fh-wedel.de"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            FH Wedel
                        </a>{' '}
                        und arbeite dort auch. 
                        Zuvor habe ich ebenfalls an der FH Wedel in Computer Games Technology meinen Bachelor gemacht.
                    </p>
                    <p>
                        Während meines Bachelor-Studiums habe ich als Entwickler bei{' '}
                        <a
                            href="https://mondula.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Mondula
                        </a>{' '}
                        in Altona gearbeitet.
                    </p>
                </article>
            </Layout>
        );
    }
}

export default AboutmePage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
